import { SF_URL } from '@/constants/env';
import { getSubscriptionRouteProps } from './routes.d';

const ROUTES = {
  cart: `${SF_URL}/cart`,
  getCartEndpoint: `${SF_URL}/api/v3/cart`,
  addToBasketEndpoint: `${SF_URL}/api/v3/cart/items`,
  helpCenter: 'https://help.bouqs.com/hc/en-us',
  retailLocations: `${SF_URL}/retail`,
  flowerCare: `${SF_URL}/flower-care-instructions`,
  emailSubscriptionEndpoint: `${SF_URL}/ajax/email_subscription`,
  aboutUsTerms: `${SF_URL}/about-us/terms`,
  logout: `${SF_URL}/logout`,
  login: `${SF_URL}/login`,
  category: {
    popular: `${SF_URL}/flowers/popular`,
  },
  subscriptions: {
    landing: `${SF_URL}/subscriptions`,
    manage: `${SF_URL}/subscriptions/manage/list`,
    new: `${SF_URL}/subscriptions/new`,
  },
  user: {
    myAccount: `${SF_URL}/users/account`,
    orders: `${SF_URL}/users/orders`,
    paymentMethods: `${SF_URL}/users/payment_methods`,
    relationships: `${SF_URL}/users/relationships`,
  },
  trackOrder: `${SF_URL}/orders/status`,
};

export const getSubscriptionRoute = ({
  isSubscriber = false,
  link,
}: getSubscriptionRouteProps): string => {
  const isSubscriptionLink = link.match(/\/subscriptions/);

  if (!isSubscriptionLink) return;

  if (isSubscriber) {
    return ROUTES.subscriptions.manage;
  } else {
    return ROUTES.subscriptions.landing;
  }
};

export default ROUTES;
