import { SystemStyleObject } from '@thebouqs/ui';

export type StyledBlockProps = {
  variant: 'light' | 'dark';
  focusPoint: 'left' | 'right' | 'center';
  contentPosition: 'left' | 'right';
  showDescription: boolean;
  isMobile: boolean;
  className?: string;
  key?: string;
};

const resolveColor = (props: StyledBlockProps) => {
  return props?.variant === 'light' ? 'white' : 'text';
};

export const CategoryBannerBlockStyles = (
  props: StyledBlockProps
): SystemStyleObject => {
  return {
    height: 'auto',
    position: 'relative',
    marginBottom: [null, null, 'md'],

    '.category-banner-content-block__img': {
      objectFit: 'cover',
      objectPosition: props?.focusPoint,
      position: 'absolute',
      display: ['none', 'block'],
      zIndex: 'hide',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      color: 'currentColor',
    },

    '.area-block-content': {
      height: '100%',
      maxWidth: null,
      marginRight: ['auto', props?.contentPosition === 'right' ? '0' : null],
      marginLeft: ['auto', props?.contentPosition === 'left' ? '0' : null],
      py: ['md', null, 'md'],
      px: ['lg', null, 'xl'],
      flexDirection: 'column',
      justifyContent: 'center',
      color: ['text', resolveColor(props)],
      textAlign: ['center', props?.contentPosition ?? 'left'],
      bgGradient: 'none',
      paddingLeft: ['0', '0', '0'],
    },

    '.category-banner-content-block__title': {
      textAlign: 'left',
      mx: ['0', 'none'],
      color: 'currentColor',
      _after: {
        content: "''",
        display: ['none', 'none'],
        width: '6',
        height: '1px',
        mt: '5',
        marginRight: ['auto', props?.contentPosition === 'right' ? '0' : null],
        marginLeft: ['auto', props?.contentPosition === 'left' ? '0' : null],
        background: ['text', resolveColor(props)],
      },
    },

    '.category-banner-content-block__description': {
      letterSpacing: 'wide',
      color: 'currentColor',
      textAlign: 'left',
    },

    '.category-banner-content-block__toggle-description-button': {
      textAlign: 'left',
      paddingLeft: '0',
      color: 'primary',
      fontSize: 'sm',
      backgroundColor: 'transparent',
      margin: 'auto',
      cursor: 'pointer',
      border: 'none',
      textDecoration: 'underline',
      display: 'inline-block',
      marginLeft: '0',
      fontWeight: '600',
    },

    '.category-banner-content-block__value-add': {
      mt: '5',
      color: 'currentColor',
    },
  };
};
