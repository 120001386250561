import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useMemo,
} from 'react';
import axios from 'axios';
import TranslatedText from '@/components/TranslatedText';
import {
  Button,
  floral,
  Text as BouqsText,
  Flex,
  Modal,
  ModalCloseButton,
} from '@thebouqs/ui';
import { BouqsLogo } from '@thebouqs/icons';
import { commonInt, errorInt } from '@/i18n/translators';
import useTranslation from '@/hooks/useTranslation';
import useIdle from '@/hooks/useIdle';
import SFInput from '@/components/Form/Input';
import useAnalytics from '@/hooks/useAnalytics';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import ROUTES from '@/routes';
import { makeImageSourceSet } from '@/utils/imageUtilities';
import useUser from '@/hooks/useUser';

const { emailSubscriptionEndpoint, aboutUsTerms } = ROUTES;

import { ExitIntentBlockProps } from '../ExitIntentBlock';
import { EXIT_INTENT_KEY } from '../ExitIntentBlock.constants';

type ExitIntentProps = ExitIntentBlockProps['data'];

export interface ExitIntentBlockModalProps extends ExitIntentProps {
  setWasShown: Dispatch<SetStateAction<boolean>>;
}

const emailFormInitialState = {
  errorMessage: null,
  value: '',
};

/**
 * STYLE
 */
const MARGIN_BOTTOM = '4';

/**
 * TRACKING
 */

type NetworkState = {
  state: NetworkStateTypes;
  error?: NetworkError;
};

type NetworkStateTypes = 'IDLE' | 'LOADING' | 'OK' | 'ERROR';

type NetworkError = {
  status: number;
};

export function ExitIntentBlockModal(
  props: ExitIntentBlockModalProps
): JSX.Element {
  const {
    header,
    subheader,
    button_text,
    cancel_button_text,
    media,
    setWasShown,
  } = props;

  const [open, setOpen] = useState(false);

  const mediaSrcSet = useMemo(
    () =>
      makeImageSourceSet(media.match(/\/media\/.*/)[0], {
        bpConfig: {
          SM: {
            quality: 5,
            size: 500,
          },
          MD: {
            quality: 40,
            size: 620,
          },
          LG: {
            quality: 80,
            size: 820,
          },
        },
      }),
    [media]
  );

  const [emailForm, setEmailForm] = useState(emailFormInitialState);
  const { value: emailValue, errorMessage } = emailForm;
  const hasInputError = errorMessage !== null;
  const inputRef = useRef();
  const { is_subscriber: isSubscriber } = useUser();

  const { track } = useAnalytics();
  const [networkState, setNetworkState] = useState<NetworkState>({
    state: 'IDLE',
  });

  const isLoading = networkState.state === 'LOADING';
  const hasNetworkError = networkState.state === 'ERROR';
  const hasAnyError = hasInputError || hasNetworkError;

  const { t } = useTranslation();
  const isIdle = useIdle();

  const [isImageReady, setIsImageReady] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setWasShown(true);
    sessionStorage.setItem(EXIT_INTENT_KEY, 'true');
  };

  const closeModalWithTracking = () => {
    closeModal();
    track('Continue Without Offer',{
        category: 'Exit Intent Modal',
        is_subscriber: isSubscriber || false,
    });
  };

  useEffect(() => {
    let domImage = document.createElement('img');

    domImage.srcset = mediaSrcSet.join();

    const onLoad = () => {
      setIsImageReady(true);
    };
    
    domImage.addEventListener('load', onLoad, true);

    return () => {
      domImage.removeEventListener('load', onLoad, true);
      domImage = null;
    };
  }, [media, mediaSrcSet]);

  useEffect(() => {
    if (isIdle) {
      setOpen(true);
    }
  }, [isIdle]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const validationErr = emailValidator(emailValue);
    if (validationErr) {
      setEmailForm({ ...emailForm, errorMessage: validationErr });
      return;
    } else {
      try {
        setNetworkState({ state: 'LOADING' });
        await axios.post(emailSubscriptionEndpoint, {
          email: emailValue,
        });
        setNetworkState({ state: 'OK' });
        track('Exit Intent Email Submitted', {
            category: 'Exit Intent Modal',
            is_subscriber: isSubscriber || false,
        });
        closeModal();
      } catch (error) {
        setNetworkState({ state: 'ERROR', error: { status: 500 } });
      }
    }
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailForm({ value: e.target.value, errorMessage: null });
  };

  const getErrorClass = () => (hasInputError ? 'has-error-state' : '');

  const emailInt = t(commonInt('emailAddress'));

  const disableSubmitButton =
    hasInputError || isLoading || emailValue.length === 0;

  const { isMobile } = useWindowDimensions();
  return (
    <Modal
      initialFocusRef={inputRef}
      label="exit-intent-block"
      open={open}
      callback={closeModalWithTracking}
      // @TODO Remove this once BUI-6 is done
      size={isMobile ? 'lg' : 'md'}
      CloseButton={
        <ModalCloseButton
          size="lg"
          variant="normal"
          label={t('aria.label.closeName', { name: header })}
        />
      }>
      <Flex justifyContent={['center', 'center', 'inherit']}>
        <floral.div display={['none', 'none', 'block']} w="100%">
          <floral.img
            aria-hidden
            srcSet={mediaSrcSet.join()}
            src={media}
            height="100%"
            width="100%"
            padding={isImageReady ? 'inherit' : '50%'}
          />
        </floral.div>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            py: '10',
            px: '4',
            textAlign: 'center',

            '& > *': {
              mb: MARGIN_BOTTOM,
            },

            '.has-error-state': {
              color: 'red.600',

              '&.field': {
                border: '2px solid',
                borderColor: 'red.600',
              },
            },
          }}>
          <floral.div mb={MARGIN_BOTTOM}>
            <floral.div pb="2.5rem">
              <BouqsLogo w="127px" h="67px" color="brand.primary" />
            </floral.div>
            <TranslatedText
              fontFamily="sans"
              as="h2"
              textTransform="uppercase"
              fontWeight="600"
              fontSize="lg"
              lineHeight="base"
              display="inline"
              sx={{
                '&::after': {
                  display: 'block',
                  marginTop: '4',
                  content: '" "',
                  border: '1px solid',
                  borderColor: 'lightestGray',
                },
              }}
              className="exit-intent-block__subheading">
              {header}
            </TranslatedText>
          </floral.div>
          <TranslatedText className="exit-intent-block__subheading" mb="4">
            {subheader}
          </TranslatedText>
          <floral.form
            onSubmit={onSubmit}
            display="flex"
            flexDirection="column"
            width="100%">
            <floral.div mb={MARGIN_BOTTOM}>
              <BouqsText as="label" htmlFor="email" className={getErrorClass()}>
                {emailInt}
              </BouqsText>
              <SFInput
                id="email"
                required
                value={emailValue}
                onChange={onChangeEmail}
                w="100%"
                lineHeight="5"
                colorScheme={hasInputError ? 'red' : 'none'}
                ref={inputRef}
              />
              {hasAnyError ? (
                <BouqsText className="has-error-state" textAlign="left">
                  {hasInputError
                    ? t('formControl.invalidInput', {
                        input: emailInt,
                      })
                    : t(errorInt('serverErrorTryAgain'))}
                </BouqsText>
              ) : null}
            </floral.div>
            <Button
              mb={MARGIN_BOTTOM}
              mx="auto"
              type="submit"
              variant="outline"
              colorScheme="green"
              maxW="3xl"
              isLoading={isLoading}
              disabled={disableSubmitButton}>
              <TranslatedText>{button_text}</TranslatedText>
            </Button>
          </floral.form>
          <Button
            variant="ghost"
            colorScheme="green"
            onClick={closeModalWithTracking}
            // @TODO Remove border once we have a working ghost style
            border="none">
            <TranslatedText>{cancel_button_text}</TranslatedText>
          </Button>
          <BouqsText
            fontSize="sm"
            letterSpacing="wider"
            fontWeight="semibold"
            color="gray.300"
            __css={{
              '.exit-intent-block-terms': {
                textDecoration: 'underline',
              },
            }}>
            {t(commonInt('termsAndConditionsAgreement'), {
              // @IDEA: Probably in a distant future we'd want to translate this URL
              c: (chunks: string) => (
                <a
                  href={`${aboutUsTerms}?exit_intent`}
                  className="exit-intent-block-terms">
                  {chunks}
                </a>
              ),
            })}
          </BouqsText>
        </Flex>
      </Flex>
    </Modal>
  );
}

function emailValidator(email: string): string {
  if (
    // From https://emailregex.com/
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  ) {
    return 'email.invalid';
  }
  return '';
}
