import { createIcon } from '@thebouqs/icons';

const PointingArrowIcon = createIcon({
  displayName: 'pointingArrowIcon',
  viewBox: '0 0 45 48',
  path: (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-381.000000, -2088.000000)">
      <g transform="translate(9.000000, 1701.000000)" fill="#333333">
        <path
          d="M375.783903,434.881244 C376.255868,434.640005 376.37578,434.280099 376.138261,433.801525 C374.243486,429.837088 373.433307,426.037381 373.700036,422.401622 C373.967533,418.766644 374.955276,415.494696 376.673256,412.57875 C378.387393,409.666708 380.753364,407.068511 383.771167,404.784158 C387.912,401.719097 392.670844,399.602597 398.057692,398.431535 C403.438392,397.260472 408.672275,397.306534 413.761648,398.565817 C412.815413,398.867951 409.888313,400.188128 404.978809,402.533376 C404.504539,402.71372 404.372327,403.061135 404.5791,403.570157 C404.786641,404.083082 405.155603,404.216583 405.687524,403.976125 C406.161794,403.795782 407.002721,403.405427 408.215684,402.805063 C409.428647,402.203917 410.344135,401.768282 410.96599,401.499718 C411.587076,401.228032 412.386495,400.898573 413.361938,400.505877 C414.338151,400.117865 415.238265,399.800897 416.067662,399.560439 C416.361294,399.499544 416.568835,399.322323 416.688748,399.020189 C416.806355,398.959294 416.896289,398.840626 416.956246,398.65716 C417.073084,398.239481 416.956246,397.906899 416.601888,397.666441 L416.424325,397.486097 C414.412712,395.681099 412.87537,394.092358 411.812297,392.710504 C410.746919,391.329431 409.978247,389.645443 409.506283,387.663225 C409.38637,387.123756 409.061991,386.912964 408.530839,387.032413 C407.998919,387.154203 407.789071,387.483662 407.908984,388.023132 C408.677655,391.20686 410.392561,394.092358 413.056007,396.674941 C407.96894,395.592879 402.702773,395.668608 397.265192,396.900566 C391.812238,398.130181 386.992669,400.338024 382.792649,403.51863 C379.597282,405.860755 377.084495,408.596357 375.249677,411.71919 C373.414858,414.842023 372.352555,418.358334 372.055079,422.261875 C371.760678,426.165417 372.646187,430.251644 374.718525,434.518215 C374.955276,434.999912 375.309633,435.11936 375.783903,434.881244 Z"
          transform="translate(394.500000, 411.000000) scale(1, -1) translate(-394.500000, -411.000000)"
        />
      </g>
    </g>
  ),
});

export default PointingArrowIcon;
