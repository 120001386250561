import { Collapse } from '@chakra-ui/transition';
import { ChevronDownIcon, ChevronUpIcon } from '@thebouqs/icons';
import { Box, Flex, Heading, IconButton } from '@thebouqs/ui';
import React, { useEffect, useState } from 'react';
import {
  AccordionProps,
  AccordionItemProps,
  AccordionItemHeadingProps,
  AccordionItemPanelProps,
} from './Accordion.d';

// Accordion component (wrapper for all accordion items)
const Accordion: React.FC<AccordionProps> = ({
  allowToggle = true,
  children,
  addonSelected,
  index,
}) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  useEffect(() => {
    // If addonSelected is truthy (true), collapse all items
    if (addonSelected) {
      setOpenIndexes([]); // Collapse all if addonSelected is true
    } else if (
      addonSelected === undefined ||
      addonSelected === false ||
      !addonSelected
    ) {
      // Expand to the specific index if addonSelected is undefined or false
      if (index !== undefined) {
        setOpenIndexes([index]); // Expand the accordion to the specific index
      } else {
        setOpenIndexes([]); // In case no index is passed, collapse all
      }
    }
  }, [addonSelected, index]); // This effect runs whenever addonSelected or index changes

  const toggleItem = (itemIndex: number) => {
    if (allowToggle) {
      setOpenIndexes(prevIndexes => {
        // If the item is already open, close it; otherwise, open it
        if (prevIndexes.includes(itemIndex)) {
          return prevIndexes.filter(index => index !== itemIndex);
        } else {
          return allowToggle ? [...prevIndexes, itemIndex] : [itemIndex];
        }
      });
    }
  };

  return (
    <Box as="div" role="region">
      {React.Children.map(children, (child, childIndex) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              isOpen: openIndexes.includes(childIndex),
              onToggle: () => toggleItem(childIndex),
            })
          : child
      )}
    </Box>
  );
};

// AccordionItem component (single item in accordion)
export const AccordionItem: React.FC<AccordionItemProps> = ({
  isOpen,
  onToggle,
  children,
}) => {
  return (
    <Box>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, { isOpen, onToggle })
          : child
      )}
    </Box>
  );
};

// AccordionItemHeading component (clickable heading)
export const AccordionItemHeading: React.FC<AccordionItemHeadingProps> = ({
  title,
  isOpen,
  onToggle,
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={onToggle}
      marginBottom="16px">
      <Heading
        as="h3"
        fontSize="1.14rem"
        fontFamily="proxima-nova, sans-serif"
        fontWeight="600"
        letterSpacing="0.1em">
        {title}
      </Heading>
      <IconButton
        aria-label="Toggle Accordion"
        icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        variant="ghost"
        fontSize="1.6rem"
        border="none"
      />
    </Flex>
  );
};

// AccordionItemPanel component (collapsible content)
export const AccordionItemPanel: React.FC<AccordionItemPanelProps> = ({
  isOpen,
  children,
}) => {
  return (
    <Collapse in={isOpen} animateOpacity>
      <Box>{children}</Box>
    </Collapse>
  );
};

export default Accordion;
