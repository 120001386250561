import { useEffect, useState, useMemo } from 'react';
import { _tryFetch } from '@/lib/bouqsFetch';
import {
  Carousel,
  Modal,
  ModalCloseButton,
  Box,
  Text,
  useBreakpointValue,
  HStack,
  Link,
} from '@thebouqs/ui';
import useTranslation from '@/hooks/useTranslation';
import useBreakpoint from '@/hooks/useBreakpoint';
import TranslatedText from '@/components/TranslatedText';
import isBrowser from '@/utils/isBrowser';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import {
  OlapicDataProps,
  OlapicBlockProps,
  OlapicResponseProps,
  OlapicModalProps,
  OlapicSliderProps,
} from './Olapic.d';
import { COLORS } from '@/constants/styles/colors';

const OLAPIC_ITEMS = 10;

export const fetchOlapicCustomer = async (
  widgetInstance: string
): Promise<string> => {
  if (isBrowser()) {
    const OLAPIC_API_URL = `https://photorankapi-a.akamaihd.net/widgets/${widgetInstance}/?auth_token=859359205441e9cf33794b22c00502b97e5cf97832f8b56299c488f2bcf3a1bd&wrap_responses=1&version=v2.2`;

    const res: OlapicResponseProps | undefined = await _tryFetch(
      OLAPIC_API_URL
    );
    if (res && res?.metadata?.code == 200) {
      return res.data?._embedded?.customer?.id ?? '';
    }
  }
};
export const fetchOlapicStream = async (
  customerId: string,
  tagKey: string
): Promise<string> => {
  if (isBrowser()) {
    const OLAPIC_API_URL = `https://photorankapi-a.akamaihd.net/customers/${customerId}/streams/search?auth_token=859359205441e9cf33794b22c00502b97e5cf97832f8b56299c488f2bcf3a1bd&wrap_responses=1&version=v2.2&tag_key=${tagKey}`;

    const response: OlapicResponseProps | undefined = await _tryFetch(
      OLAPIC_API_URL
    );
    if (response && response?.metadata?.code === 200) {
      return response.data?.id;
    }
  }
};

// speeding up page load by deferring to the browser
export const fetchOlapic = async (
  streamId?: string | null
): Promise<undefined[] | OlapicDataProps[]> => {
  if (isBrowser()) {
    const OLAPIC_API_URL = streamId
      ? `https://photorankapi-a.akamaihd.net/streams/${streamId}/media/shuffled?auth_token=859359205441e9cf33794b22c00502b97e5cf97832f8b56299c488f2bcf3a1bd&version=v2.2&wrap_responses=1&count=9&rights_given=true`
      : 'https://photorankapi-a.akamaihd.net/streams/2195673427/media/media_position?version=v2.2&auth_token=859359205441e9cf33794b22c00502b97e5cf97832f8b56299c488f2bcf3a1bd&rights_given=true';

    const res: OlapicResponseProps | undefined = await _tryFetch(
      OLAPIC_API_URL
    );
    if (res && res?.metadata?.code == 200) {
      return res.data?._embedded?.media ?? [];
    }
  }
};

// ToDo: exporting this module so I can disable it without commenting its dependencies you may use the export for testing functionality though
export const OlapicModal = ({
  olapicData,
  open,
  setOpen,
}: OlapicModalProps): JSX.Element => {
  const { t } = useTranslation();
  const modalLabel = t('aria.label.modalName', { name: 'olapic' });
  const embeddedStreams =
    olapicData?._embedded['streams:all']?._embedded?.stream ?? [];
  const streamData = embeddedStreams[0];

  // ToDo: why does this particular closeButton havs a border?
  // ToDo: What's add arrows that call setOpen with the prev/next position
  return (
    <Modal
      CloseButton={
        <ModalCloseButton
          label={t('aria.label.closeName', { name: 'olapic' })}
          variant="normal"
          size="sm"
        />
      }
      callback={() => setOpen(false)}
      size="md"
      open={open}
      label={modalLabel}>
      <Box
        sx={{
          display: 'grid',
          gap: 25,
          gridTemplateAreas: `
            "img info"
            "img shop"
            `,
          gridTemplateRows: 'auto auto',
          gridTemplateColumns: '55% 1fr',
        }}>
        <img
          alt="thumbnail of user generated content"
          src={olapicData?.images?.original}
          style={{
            gridArea: 'img',
            height: '100%',
            width: 'auto',
            objectFit: 'contain',
          }}
        />
        <Box
          sx={{
            gridArea: 'info',
          }}>
          <Text as="a" href={olapicData?.original_source} display="flex">
            <img
              alt="user avatar"
              src={olapicData?._embedded?.uploader?.avatar_url}
            />
            {olapicData?._embedded?.uploader?.username}
          </Text>
          <Text
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '8',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}>
            {olapicData?.caption}
          </Text>
        </Box>
        <Box
          sx={{
            gridArea: 'shop',
            textAlign: 'center',
          }}>
          <TranslatedText
            t="common.shopThisBouq"
            as="h3"
            sx={{ fontSize: 'xl', fontWeight: 'normal' }}
          />
          <Text as="a" href={streamData?.share_url}>
            <Box sx={{ mx: 'auto', mt: 7, mb: 4 }}>
              <img
                alt="bouq thumbnail"
                src={streamData?._embedded?.base_image?.images?.thumbnail}
                style={{
                  display: 'block',
                  margin: 'auto',
                }}
              />
            </Box>
            {streamData?.name}
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

export const OlapicSlider = ({
  data,
  isProduct = false,
}: OlapicSliderProps): JSX.Element => {
  // const [olapicModal, setOlapicModal] = useState<number | false>(false);

  const { isMobile } = useWindowDimensions();
  const workAroundBreakpointVal = isMobile ? 1 : 3;
  /*
    with 'fix' for useBreakpointValue of mapping numbers as keys, 4 kept being returned on desktop and mobile
  */
  const sliderSpan = useBreakpointValue(
    {
      base: 1,
      sm: 1.5,
      md: 2.5,
      lg: 3,
      xl: 4,
      1: 1.5,
      2: 2.5,
      4: workAroundBreakpointVal,
      0: 1,
      3: 5,
    },
    'base'
  );

  const truncate = (text = '', max: number): string =>
    text.length > max ? text.slice(0, max) + '…' : text;

  const slides = useMemo(() => {
    return data.map((slide, i) => {
      return (
        <Box
          className="olapic-item"
          // ToDo: re-enable these handlers when you do the olapic modal feature
          // onClickCapture={ev => {
          //   setOlapicModal(i);
          //   ev.preventDefault();
          //   return false;
          // }}
          // onClick={ev => {
          //   ev.preventDefault();
          //   ev.preventDefault();
          //   return false;
          // }}
          key={`olapic-${slide.id}`}
          data-index={i}
          sx={{
            position: 'relative',
            mx: '1.5',
            height: '100%',
          }}>
          <img
            src={slide.images.normal}
            alt="thumbnail of user generated content via instagram"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
            }}
          />
          <Box
            className="olapic-username-overlay"
            sx={{
              w: 'full',
              h: 'full',
              top: 0,
              bg: 'blackAlpha.600',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              opacity: 0,
              transitionProperty: 'opacity',
              transitionDuration: 'normal',
              transitionTimingFunction: 'ease-out',

              '&:hover, &:active, &:focus': {
                opacity: 1,
              },
            }}>
            {isProduct ? (
              <Text
                sx={{
                  color: 'white',
                  fontSize: 'sm',
                  fontWeight: 'normal',
                  px: '24px',
                  py: '8px',
                  textAlign: 'center',
                  lineHeight: '1.5',
                }}>
                {isProduct && truncate(slide.caption, 400)}
                <br />
                {slide._embedded?.uploader?.username}
              </Text>
            ) : (
              <Text
                sx={{
                  color: 'white',
                  fontSize: 'xl',
                  fontWeight: 'normal',
                }}>
                @{slide._embedded?.uploader?.username}
              </Text>
            )}
          </Box>
        </Box>
      );
    });
  }, [data, isProduct]);

  // ToDo: Why do sf-ui buttons have a border?
  // ToDo: Why is the hover style not working in sf-ui?
  // ToDo: add ul styles to carousel.slider theme
  // ToDo: implement maxHeight in the slider + carouselViewport height
  // ToDo: why is li: position: relative needed? it should've been enought with the content's position
  // ToDo: seamless carousel skips to the beginning
  // ToDo: width should loosely match slider span
  return (
    <>
      <Carousel
        slides={slides}
        width="100%"
        seamless={false}
        initialSlide={0}
        span={sliderSpan}
        {...{
          '& .carousel-viewport': { mx: '-1.5', height: '100%' },
          '& button': { border: 'none' },
          '& ul': { alignItems: 'stretch', m: 0, alignContent: 'flex-start' },
          '& .carousel-viewport *': { maxHeight: '100%' },
          height: 'xs',

          '& li.carousel-slide': {
            position: 'relative',
            height: 'auto',
            maxWidth: 'xs',
            flexGrow: 0,
            flexBasis: `${100 / sliderSpan}%`,
          },
        }}
      />
      {/* <OlapicModal
        olapicData={data[olapicModal as number]}
        open={olapicModal !== false}
        setOpen={setOlapicModal}
      /> */}
    </>
  );
};

export const OlapicSliderFooter = (): JSX.Element => {
  return (
    <Box className="olapic-slider-footer">
      {/* 
        The following attribution MUST NOT be removed as per our licensing agreement.
        You may change the location or the styling to better match your site, 
        but the wording and the link must remain in the template and visible to all end users.
      */}

      {/* If needed later, action buttons can be restored here */}

      <HStack className="olapic-slider-copy" spacing={4} justify="right" mt={4}>
        <Link
          className="olapic-terms-of-service"
          href="#"
          target="_blank"
          sx={{
            color: COLORS.DARKEST_GRAY,
            display: 'none',
          }}>
          Terms of Service
        </Link>

        <Link
          className="olapic-copyright"
          href="https://www.olapic.com/?utm_source=referral&utm_medium=powered-by-olapic&utm_campaign=thebouqs"
          target="_blank"
          aria-label="go to third-party website"
          display="flex"
          alignItems="center"
          fontSize="sm"
          color={COLORS.DARKEST_GRAY}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}>
          <Text mr={2} textDecoration="none">
            Powered by
          </Text>
          <Box lineHeight="1" textAlign="left">
            <Text
              color={COLORS.DARKEST_GRAY}
              fontWeight="bold"
              letterSpacing="wide"
              textTransform="uppercase">
              Social
              <br />
              Native
            </Text>
          </Box>
        </Link>
      </HStack>
    </Box>
  );
};

const Olapic = (props: OlapicBlockProps): JSX.Element => {
  const {
    data: { heading, isPDP, widget },
  } = props;
  const { isDesktop } = useBreakpoint();
  const [olapicEntries, setOlapicEntries] = useState([]);
  const isProduct = isPDP ?? false;
  const widget_instance = widget?.widget_instance ?? null;
  const widget_source = widget?.widget_source ?? null;

  useEffect(() => {
    if (!isProduct) {
      fetchOlapic().then(olapicData => {
        olapicData && setOlapicEntries(olapicData.slice(0, OLAPIC_ITEMS));
      });
      return;
    }
    if (isProduct && widget_instance && widget_source) {
      fetchOlapicCustomer(widget_instance).then(customerId => {
        customerId &&
          fetchOlapicStream(customerId, widget_source).then(streamId => {
            streamId &&
              fetchOlapic(streamId).then(olapicData => {
                olapicData &&
                  setOlapicEntries(olapicData.slice(0, OLAPIC_ITEMS));
              });
          });
      });
    }
  }, [isProduct, widget_instance, widget_source]);

  return (
    <>
      {heading && olapicEntries.length ? (
        <>
          {isProduct && (
            <Text
              sx={{
                fontSize: { base: '18px', md: '24px !important' },
                fontWeight: 'semibold',
                fontFamily: 'sans',
                letterSpacing: 'widest',
                color: isProduct ? COLORS.DARKEST_GRAY : 'gray.600',
                textAlign: 'center',
                mb: 5,
              }}
              casing="uppercase">
              Over 45,000{' '}
              <Text as="span" color="yellow.400" fontWeight="bold">
                ★★★★★
              </Text>{' '}
              (5-Star) Reviews on TrustPilot
            </Text>
          )}
          <Text
            sx={{
              fontSize: isProduct
                ? { base: '18px', md: '24px !important' }
                : '2xl',
              fontWeight: 'semibold',
              fontFamily: 'sans',
              letterSpacing: 'widest',
              color: isProduct ? COLORS.DARKEST_GRAY : 'gray.600',
              textAlign: 'center',
              ...(isProduct ? { mb: 5 } : { m: 5 }),
            }}
            casing="uppercase">
            {heading}
          </Text>
        </>
      ) : null}
      {olapicEntries.length ? (
        <OlapicSlider data={olapicEntries} isProduct={isProduct} />
      ) : null}
      {isDesktop && isProduct && olapicEntries.length ? (
        <OlapicSliderFooter />
      ) : null}
    </>
  );
};

export default Olapic;
