import styled from '@emotion/styled';
import { Box } from '@thebouqs/ui';
import { customShouldForwardProp } from '@/styles/util/shouldForwardProp';
import { MEDIA_QUERIES } from '@/constants/styles';

// Wrapper for the cart drawer container
const CartDrawerWrapper = styled(Box, {
  shouldForwardProp: customShouldForwardProp(['theme']),
})`
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);

  &.open {
    transform: translateX(0);
  }
`;

// Backdrop styles
export const Backdrop = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
`;

// Close button styles
export const CloseButton = styled.button`
  position: absolute;
  top: -7px;
  right: -4px;
  background: transparent;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #333;
  &.close-drawer-button {
    right: 2px;
  }
  ${MEDIA_QUERIES.RESPOND_DOWN.SM} {
    top: -11px;
    right: 14px;
    padding: 0;
  }
  @media (max-width: 768px) {
    &.close-item-button {
      font-size: 25px;
      top: 0;
      right: 0;
      padding: 0;
    }
  }
`;
// Heading styles
export const DrawerHeading = styled.h1`
  text-transform: uppercase;
  font-family: 'proxima-nova', sans-serif;
  margin: 1.5em auto;
  color: #172333;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.09em;
`;

export default CartDrawerWrapper;
