import { CheckIcon } from '@thebouqs/icons';
import { Box, Button, Flex, Heading, Text } from '@thebouqs/ui';
import React, { useEffect, useState } from 'react';
import CartDrawerWrapper, {
  Backdrop,
  CloseButton,
  DrawerHeading,
} from './CartDrawer.styles'; // Importing the styled components
import { CartDrawerProps } from './CartDrawer.d';
import { useRouter } from 'next/router';
import useTranslation from '@/hooks/useTranslation';
import useUser from '@/hooks/useUser';
import { SF_URL } from '@/constants/env';

import Accordion from '@/components/Accordion';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/components/Accordion/Accordion';
import ROUTES from '@/routes';
import axios from 'axios';

const { addToBasketEndpoint, getCartEndpoint } = ROUTES;
const CartDrawer: React.FC<CartDrawerProps> = ({
  eligibleForSubs,
  isDrawerOpen,
  toggleDrawer,
  cartProducts,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { is_subscriber, subscription_activation_used, cart_quantity } =
    useUser();

  const [localCartProducts, setLocalCartProducts] = useState(cartProducts);
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);

  const formatPrice = (price: string | number) => {
    const num = parseFloat(price as string);
    if (Number.isInteger(num)) {
      return num.toString();
    }
    return num.toFixed(2);
  };

  // const hasDiscountableProduct = cartProducts?.items.some(
  //   item => item.product_type === 'prepaid' || item.product_type === 'gift_card'
  // );

  // this checks the if one of the items in the cart is a bouq product
  const hasBouqProduct = cartProducts?.items?.some(
    item => item.product_type === 'bouq'
  );
  const formatDate = dateString => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short', // 'Mar'
      day: '2-digit', // '21'
      year: 'numeric', // '2025'
    }).format(date);
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.classList.add('drawer-open');
    } else {
      document.body.classList.remove('drawer-open');
    }
    return () => {
      document.body.classList.remove('drawer-open');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('height');
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleCartIconClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      const isCartIcon = target.closest('.cart-icon');
      if (isCartIcon) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation?.();
        toggleDrawer?.();
      }
    };
    document.addEventListener('click', handleCartIconClick, true); // useCapture = true
    return () =>
      document.removeEventListener('click', handleCartIconClick, true);
  }, [toggleDrawer]);

  useEffect(() => {
    if (typeof document !== undefined) {
      const cartCount = cartProducts?.items?.length ?? 0;
      const elements = document.querySelectorAll('p.numItems');
      if (elements.length > 0) {
        elements[0].textContent = cartCount.toString();
      }
    }
  }, [cartProducts]);

  useEffect(() => {
    setLocalCartProducts(cartProducts);
  }, [cartProducts]);

  const handleRemoveVaseAddonItem = async (itemId, reqData) => {
    const response = await axios.patch(
      `${addToBasketEndpoint}/${itemId}`,
      reqData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = response;
    setLocalCartProducts(data);
  };

  const handleAddRemoveVaseAddonItem = async (itemId, reqData) => {
    const response = await axios.patch(
      `${addToBasketEndpoint}/${itemId}`,
      reqData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const { data } = response;
    setLocalCartProducts(data);
  };

  const handleDeleteItem = async itemId => {
    const response = await axios.delete(`${addToBasketEndpoint}/${itemId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = response;
    setLocalCartProducts(data);
  };

  const handleSubscription = async reqData => {
    try {
      // const getCartEndpoint = '/api/v3/cart';
      setIsUpdatingCart(true);
      const response = await axios.patch(getCartEndpoint, reqData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { data } = response;
      setLocalCartProducts(data);
    } catch (error) {
      console.error('Error updating cart:', error);
    } finally {
      setIsUpdatingCart(false); // Stop loading after response
    }
  };

  return (
    <>
      {/* Backdrop */}
      <Backdrop
        className={isDrawerOpen ? 'visible' : ''}
        onClick={toggleDrawer}
      />

      {/* Cart Drawer */}
      <CartDrawerWrapper
        width={['90%', '80%', '60%', '40%', '30%']}
        className={
          isDrawerOpen ? 'open cart-drawer-wrap' : ' cart-drawer-wrap'
        }>
        {/* Cart Drawer Content */}
        <Box
          height={['100%', '100%', '100%', '100%', '100%']}
          maxHeight={
            (localCartProducts?.items && localCartProducts.items.length > 0) ||
            cart_quantity > 0
              ? 'calc(100dvh - 180px)'
              : '100dvh'
          } // Adjust the dynamic height as needed
          style={{
            overflowY: 'auto', // Make it scrollable
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none', // For IE and Edge
            position: 'relative',
            background: '#f2f4f6',
          }}
          css={`
            &::-webkit-scrollbar {
              display: none; // For Chrome, Safari, and Opera
            }
          `}>
          <CloseButton className="close-drawer-button" onClick={toggleDrawer}>
            &times;
          </CloseButton>
          <Flex
            flexDirection="column"
            backgroundColor="#f2f4f6"
            color="#333"
            fontSize="14px"
            fontFamily="proxima-nova, sans-serif"
            textAlign="center"
            height="fit-content">
            <DrawerHeading as="h1">{t('cartDrawer.title')}</DrawerHeading>
          </Flex>

          {(localCartProducts?.items && localCartProducts.items.length > 0) ||
          cart_quantity > 0 ? (
            localCartProducts?.items
              ?.slice()
              .reverse()
              .map((item, index) => (
                <>
                  <Flex
                    key={index}
                    flexDirection="column"
                    backgroundColor="#fff"
                    color="#333"
                    fontSize="14px"
                    fontFamily="proxima-nova, sans-serif"
                    padding="0 1.15em"
                    marginBottom="10px">
                    <Flex padding="1.15em 0" position="relative">
                      <Box
                        key={`gallery-preview-item-`}
                        width="7.25rem"
                        height="7.25rem"
                        flexBasis="7.25rem">
                        <img
                          src={item.image}
                          alt={`cart-image`}
                          data-testid={`cart-image`}
                          // width="100%"
                          // layout="fill"
                        />
                      </Box>
                      <Flex
                        flexDirection="column"
                        minHeight="6rem"
                        marginLeft="1.15em">
                        <Heading
                          as="h3"
                          paddingRight="1.5em"
                          color="#333333"
                          fontSize="16px"
                          letterSpacing="0.1em"
                          fontWeight="600"
                          textTransform="uppercase"
                          className="cart-product-heading"
                          fontFamily={'proxima-nova, sans-serif'}>
                          {item.name}
                        </Heading>
                        {item.size && (
                          <Text marginBottom=".25em">
                            {t('cartDrawer.showTitle')}: {item.size}
                          </Text>
                        )}
                        {item.product_type != 'prepaid' && (
                          <>
                            {item.delivery_date && (
                              <Text marginBottom=".25em">
                                {t('cartDrawer.deliveryDateTitle')}:{' '}
                                {formatDate(item.delivery_date)}
                              </Text>
                            )}
                            {item.customizations?.delivery_window?.label && (
                              <Text marginBottom=".25em">
                                {t('cartDrawer.deliveryWindowtitle')}:{' '}
                                {item.customizations?.delivery_window?.label}
                              </Text>
                            )}
                          </>
                        )}
                        {item.product_type === 'bouq' && (
                          <>
                            {localCartProducts.create_subscription ? (
                              eligibleForSubs ? (
                                <>
                                  <Text
                                    marginBottom=".25em"
                                    color="#085b45"
                                    fontWeight="600">
                                    {item.discountable ? (
                                      <>
                                        ${formatPrice(item.subscriber_price)}{' '}
                                        {t('cartDrawer.subscriptionPriceTitle')}
                                      </>
                                    ) : (
                                      <>
                                        ${formatPrice(item.price)}
                                        {t('cartDrawer.regularPriceTitle')}
                                      </>
                                    )}
                                  </Text>
                                  <Text
                                    marginBottom=".25em"
                                    style={{
                                      //   marginRight: '10px',
                                      color: '#999999',
                                      //   fontWeight: '400',
                                      textDecoration: 'line-through',
                                      textDecorationThickness: '1px',
                                      //   fontFamily: 'proxima-nova, sans-serif',
                                    }}>
                                    ${formatPrice(item.price)}{' '}
                                    {t('cartDrawer.regularPriceTitle')}
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Text
                                    marginBottom=".25em"
                                    color="#333333"
                                    fontWeight="600">
                                    ${formatPrice(item.price)}{' '}
                                    {t('cartDrawer.regularPriceTitle')}
                                  </Text>
                                </>
                              )
                            ) : (
                              <Text
                                marginBottom=".25em"
                                color="#333333"
                                fontWeight="600">
                                ${formatPrice(item.price)}{' '}
                                {t('cartDrawer.regularPriceTitle')}
                              </Text>
                            )}
                          </>
                        )}
                        {(item.product_type === 'prepaid' ||
                          item.product_type === 'gift_card') && (
                          <Text
                            marginBottom=".25em"
                            color="#333333"
                            fontWeight="600">
                            {/* ${item.price}{' '} */}
                            {item.discountable ? (
                              <>
                                ${formatPrice(item.subscriber_price)}{' '}
                                {t('cartDrawer.subscriptionPriceTitle')}
                              </>
                            ) : (
                              <>${formatPrice(item.price)}</>
                            )}
                          </Text>
                        )}
                      </Flex>
                      <CloseButton
                        className="close-item-button"
                        onClick={() => handleDeleteItem(item.id)}>
                        &times;
                      </CloseButton>
                    </Flex>
                    {item.vase && (
                      <Flex padding="1.15em 0" borderTop="1px solid #E2E8F0">
                        <Box
                          key={`gallery-preview-item-`}
                          // width="16%"
                          // height="3.6rem"
                          width="3.6rem"
                          height="3.6rem"
                          flexBasis="3.6rem">
                          <img
                            src={item.vase?.image}
                            alt={`cart-image`}
                            data-testid={`cart-image`}
                            style={{
                              borderRadius: '50%',
                              width: '3.6rem',
                              height: '3.6rem',
                              objectFit: 'cover',
                            }}
                            // width="100%"
                            // layout="fill"
                          />
                        </Box>
                        <Flex flexDirection="column" marginLeft="1.15em">
                          <Text marginBottom=".25em">{item.vase.name}</Text>
                          <Flex>
                            {!is_subscriber && subscription_activation_used ? (
                              <Text
                                marginBottom=".25em"
                                color="#333333"
                                fontWeight="600">
                                ${formatPrice(item.vase.price)}
                              </Text>
                            ) : (
                              <>
                                <Text
                                  marginBottom=".25em"
                                  color="#085b45"
                                  fontWeight="800">
                                  ${formatPrice(item.vase.discount_price)}
                                </Text>
                                <Text
                                  marginBottom=".25em"
                                  marginLeft=".35em"
                                  style={{
                                    color: '#999999',
                                    fontWeight: '400',
                                    textDecoration: 'line-through',
                                    textDecorationThickness: '1px',
                                  }}>
                                  ${formatPrice(item.vase.price)}
                                </Text>
                              </>
                            )}
                          </Flex>
                        </Flex>
                        <Flex
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          marginLeft="auto">
                          <Text
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            width="100%"
                            color="#144b3c"
                            cursor="pointer"
                            padding="0.5em 0.7em"
                            // onClick={onToggleModal}
                            letterSpacing=" 0.1em"
                            fontWeight="600"
                            lineHeight="1.25em"
                            fontSize="0.85rem"
                            textTransform="uppercase"
                            // _hover={{ textDecoration: 'underline' }}
                            onClick={() =>
                              handleRemoveVaseAddonItem(item.id, {
                                delivery_date: item.delivery_date,
                                addon_id: '',
                              })
                            }>
                            {t('pdp.removeOptionTitle')}
                          </Text>
                        </Flex>
                      </Flex>
                    )}

                    {item?.addons && item?.addons?.length > 0 && (
                      <Box padding="1.15em 0" borderTop="1px solid #E2E8F0">
                        <Accordion
                          allowToggle={true}
                          addonSelected={item?.addon_selected}
                          index={index}>
                          <AccordionItem>
                            <AccordionItemHeading
                              title={'Send A Little Extra Love'}
                            />
                            {item.addon_selected && item?.addon_selected?.id && (
                              <Flex
                                borderBottom="1px solid #f2f4f6"
                                padding="0.575em 0"
                                justifyContent={'space-between'}>
                                <Flex>
                                  <Box width="5.35rem" height="5.35rem">
                                    <img
                                      src={item.addon_selected.image}
                                      alt={`cart-image`}
                                      data-testid={`cart-image`}
                                    />
                                  </Box>
                                  <Flex
                                    flexDirection="column"
                                    marginLeft="1.15em">
                                    <Text marginBottom=".25em">
                                      {item.addon_selected.name}
                                    </Text>
                                    <Flex>
                                      {localCartProducts.create_subscription ? (
                                        !is_subscriber &&
                                        subscription_activation_used ? (
                                          <Text
                                            marginBottom=".25em"
                                            color="#333333"
                                            fontWeight="600">
                                            ${item.addon_selected.price}
                                          </Text>
                                        ) : (
                                          <>
                                            <Text
                                              marginBottom=".25em"
                                              color="#333333"
                                              fontWeight="600">
                                              $
                                              {
                                                item.addon_selected
                                                  .subscription_discount
                                              }
                                            </Text>
                                            <Text
                                              marginBottom=".25em"
                                              marginLeft=".35em"
                                              style={{
                                                color: '#999999',
                                                fontWeight: '400',
                                                textDecoration: 'line-through',
                                                textDecorationThickness: '1px',
                                              }}>
                                              ${item.addon_selected.price}
                                            </Text>
                                          </>
                                        )
                                      ) : (
                                        <Text
                                          marginBottom=".25em"
                                          color="#333333"
                                          fontWeight="600">
                                          ${item.addon_selected.price}
                                        </Text>
                                      )}
                                    </Flex>
                                    <Text
                                      marginBottom=".25em"
                                      color="#999999"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.addon_selected.short_description,
                                      }}></Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  alignItems="flex-start"
                                  justifyContent="flex-end">
                                  <Text
                                    display="flex"
                                    alignItems="flex-end"
                                    justifyContent="flex-end"
                                    width="100%"
                                    color="#144b3c"
                                    cursor="pointer"
                                    padding="0.5em 0.7em"
                                    letterSpacing=" 0.1em"
                                    fontWeight="600"
                                    fontSize="0.85rem"
                                    textTransform="uppercase"
                                    _hover={{
                                      textDecoration: 'underline',
                                      color: '#144b3c',
                                    }}
                                    onClick={() =>
                                      handleAddRemoveVaseAddonItem(item.id, {
                                        delivery_date:
                                          item.customizations.delivery_date,
                                        vase_id: item.customizations.vase_id,
                                      })
                                    }>
                                    {t('pdp.removeOptionTitle')}
                                  </Text>
                                </Flex>
                              </Flex>
                            )}
                            <AccordionItemPanel>
                              {item.addons.map((addon, index) => {
                                if (addon.id === item.addon_selected?.id) {
                                  return;
                                } else {
                                  return (
                                    <Flex
                                      key={index}
                                      borderBottom="1px solid #f2f4f6"
                                      padding="0.575em 0"
                                      justifyContent={'space-between'}>
                                      <Flex>
                                        <Box
                                          key={index}
                                          width="5.35rem"
                                          height="5.35rem">
                                          <img
                                            src={addon.image}
                                            alt={`cart-image`}
                                            data-testid={`cart-image`}
                                          />
                                        </Box>
                                        <Flex
                                          flexDirection="column"
                                          marginLeft="1.15em">
                                          <Text marginBottom=".25em">
                                            {addon.name}
                                          </Text>
                                          <Flex>
                                            {localCartProducts.create_subscription ? (
                                              !is_subscriber &&
                                              subscription_activation_used ? (
                                                <Text
                                                  marginBottom=".25em"
                                                  color="#333333"
                                                  fontWeight="600">
                                                  ${addon.price}
                                                </Text>
                                              ) : (
                                                <>
                                                  <Text
                                                    marginBottom=".25em"
                                                    color="#333333"
                                                    fontWeight="600">
                                                    $
                                                    {
                                                      addon.subscription_discount
                                                    }
                                                  </Text>
                                                  <Text
                                                    marginBottom=".25em"
                                                    marginLeft=".35em"
                                                    style={{
                                                      color: '#999999',
                                                      fontWeight: '400',
                                                      textDecoration:
                                                        'line-through',
                                                      textDecorationThickness:
                                                        '1px',
                                                    }}>
                                                    ${addon.price}
                                                  </Text>
                                                </>
                                              )
                                            ) : (
                                              <Text
                                                marginBottom=".25em"
                                                color="#333333"
                                                fontWeight="600">
                                                ${addon.price}
                                              </Text>
                                            )}
                                          </Flex>
                                          <Text
                                            marginBottom=".25em"
                                            color="#999999"
                                            dangerouslySetInnerHTML={{
                                              __html: addon.short_description,
                                            }}></Text>
                                        </Flex>
                                      </Flex>
                                      <Flex
                                        alignItems="flex-start"
                                        justifyContent="flex-end">
                                        <Text
                                          display="flex"
                                          alignItems="flex-end"
                                          justifyContent="flex-end"
                                          width="100%"
                                          color="#144b3c"
                                          cursor="pointer"
                                          padding="0.5em 0.7em"
                                          letterSpacing=" 0.1em"
                                          fontWeight="600"
                                          fontSize="0.85rem"
                                          textTransform="uppercase"
                                          _hover={{
                                            textDecoration: 'underline',
                                            color: '#144b3c',
                                          }}
                                          onClick={() =>
                                            handleAddRemoveVaseAddonItem(
                                              item.id,
                                              {
                                                ...item.customizations,
                                                addon_id: addon.id,
                                              }
                                            )
                                          }>
                                          Add
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  );
                                }
                              })}
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    )}
                  </Flex>
                </>
              ))
          ) : (
            <>
              <Box
                backgroundColor="#fff"
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                paddingTop="18%">
                <Text
                  color="#33333"
                  fontSize={'16px'}
                  textAlign="center"
                  marginBottom="16px">
                  {t('cartDrawer.emptyBasketMsg')}
                </Text>
                <a
                  style={{
                    textDecoration: 'none',
                    color: '#fff',
                    margin: '0 auto',
                    display: 'block',
                    width: 'max-content',
                    textAlign: 'center',
                    padding: '0.5em 4.5rem',
                    backgroundColor: '#144b3c',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    border: 'none',
                    letterSpacing: '0.1em',
                  }}
                  href={`${SF_URL}/flowers/all`}>
                  {t('common.shopAll')}
                </a>
              </Box>
            </>
          )}

          {localCartProducts?.items.length > 0 &&
          hasBouqProduct &&
          localCartProducts.create_subscription ? (
            <Box backgroundColor="#f2f4f6" padding="3.2em 0">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                marginBottom="16px">
                <CheckIcon
                  color="#144b3c"
                  marginRight="2px"
                  fontSize="1rem"
                  fontWeight="600"
                />
                <Text fontSize="14px" fontWeight="600">
                  {t('cartDrawer.addSubscription')}
                </Text>
              </Flex>
              <Text
                textAlign="center"
                cursor="pointer"
                fontWeight="400"
                fontSize="0.85rem"
                onClick={() =>
                  router.push({
                    pathname: '/cart',
                    query: { remove_subscription: 'true' },
                  })
                }
                textDecoration="underline">
                {t('cartDrawer.removeSubsription')}
              </Text>
            </Box>
          ) : (
            hasBouqProduct &&
            !is_subscriber && (
              <Box textAlign="center" className="save-30-box">
                <Text fontWeight="600" margin="20px 0 16px">
                  Save 30% on this order when you add a subscription
                </Text>
                <Button
                  cursor="pointer"
                  fontWeight="600"
                  textTransform="uppercase"
                  backgroundColor="transparent"
                  border="3px solid #144b3c"
                  borderRadius="3px"
                  padding="0.5em 1em"
                  color="#144b3c"
                  _active={{ color: '#195f4c', border: '3px solid #195f4c' }}
                  _hover={{ color: '#195f4c', border: '3px solid #195f4c' }}
                  disabled={isUpdatingCart}
                  onClick={() =>
                    handleSubscription({
                      id: localCartProducts?.id ?? '',
                      create_subscription: true,
                    })
                  }>
                  {isUpdatingCart ? 'Updating Cart...' : 'Join Now'}
                </Button>
              </Box>
            )
          )}
        </Box>
        {/* Cart Drawer Footer */}
        {(localCartProducts?.items && localCartProducts.items.length > 0) ||
        cart_quantity > 0 ? (
          <Box
            className="cart-drawer-footer-content"
            left="0"
            right="0"
            bottom="0">
            <Box backgroundColor="#f2f4f6" padding="1.2em">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                // marginBottom="16px"
                padding="8px 0"
                borderTop="1px solid #e6e9ed"
                margin="0">
                <Heading
                  as="h1"
                  margin="0"
                  color="#333333"
                  fontSize="0.998rem"
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontFamily={'proxima-nova, sans-serif'}
                  className="heading">
                  {t('cartDrawer.subtotalTitle')}:
                </Heading>
                <Heading
                  as="h1"
                  margin="0"
                  color="#333333"
                  fontSize="1.05rem"
                  fontWeight="600"
                  className="heading"
                  fontFamily={'proxima-nova, sans-serif'}>
                  ${formatPrice(localCartProducts?.subtotal_price?.cents / 100)}
                  {/* ${cartProd	ucts.subtotal_price.cents} */}
                  {/* {isDiscounted
                  ? varientPrices.subscription_discount +
                    localCartProducts?.[0]?.vase?.price
                  : varientPrices.full + localCartProducts?.[0]?.vase?.price} */}
                </Heading>
              </Flex>
              <Text
                // textAlign="center"
                // cursor="pointer"
                // fontSize="0.85rem"
                fontWeight="400">
                {t('cartDrawer.calculateLate')}
              </Text>
            </Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding="1.2em"
              width="100%"
              className="cart-drawer-footer">
              <Button
                width="90%"
                height={'2.8rem'}
                backgroundColor={'#144b3c'}
                fontSize="16px"
                letterSpacing="0.1em"
                textTransform="uppercase"
                onClick={() => router.push('/checkout')}
                border={'none'}
                _active={{ background: '#195f4c', border: 'none' }}
                _hover={{ background: '#195f4c', border: 'none' }}>
                {t('cartDrawer.checkoutBtn')}
              </Button>
              <Button
                width="90%"
                height={'2.8rem'}
                fontSize="16px"
                letterSpacing="0.1em"
                border={'none'}
                textTransform="uppercase"
                onClick={() => router.push('/cart')}
                variant="link"
                color="#144b3c"
                _active={{ color: '#195f4c' }}
                _hover={{ color: '#195f4c' }}>
                {t('cartDrawer.editOrderBtn')}
              </Button>
            </Flex>
          </Box>
        ) : null}
      </CartDrawerWrapper>
    </>
  );
};

export default CartDrawer;
