import React from 'react';
import { Box, ListItem } from '@thebouqs/ui';
import { createBouqsCdnIcon } from '@thebouqs/icons';
import useTranslation from '@/hooks/useTranslation';
import useUser from '@/hooks/useUser';
import selectUserType from '@/store/user/selectors/selectUserType';
import { SFErrorBoundary } from '@/components/ErrorBoundary';
import MenuItemErrorFallback from '@/components/TopNav/MenuItemErrorFallback';
import { BasketIcon, UserIcon } from '@/components/Icons';
import VisuallyHidden from '@/components/VisuallyHidden';
import { generateUtilityMenuData } from '@/utils/generateUtilityMenuData/generateUtilityMenuData';
import MenuLink from '../MenuLink';
import {
  TopMenuProps,
  BuildMenuContentProps,
  MenuIconProps,
} from './TopMenu.d';

const CustomIcon = ({ icon }: Pick<MenuIconProps, 'icon'>): JSX.Element => {
  const CustomMenuIcon = createBouqsCdnIcon(icon);
  return <CustomMenuIcon boxSize="7" />;
};

const MenuIcon = ({
  icon,
  isLoggedIn,
  cartQuantity,
}: MenuIconProps): JSX.Element => {
  switch (icon) {
    case 'basket':
      return (
        <Box position="relative">
          <BasketIcon quantity={cartQuantity} boxSize="7" role="presentation" />
        </Box>
      );
    case 'account':
      return (
        <UserIcon
          boxSize="6"
          color={isLoggedIn ? 'primary' : null}
          role="presentation"
        />
      );
    default:
      return <CustomIcon icon={icon} />;
  }
};

const buildMenuLinkContent = ({
  linkData,
  isLoggedIn,
  cartQuantity,
}: BuildMenuContentProps): JSX.Element => {
  const { icon, name } = linkData;

  if (icon) {
    return (
      <>
        <MenuIcon
          icon={icon}
          isLoggedIn={isLoggedIn}
          cartQuantity={cartQuantity}
        />
        <VisuallyHidden>{name}</VisuallyHidden>
      </>
    );
  } else {
    return <>{name}</>;
  }
};

const TopMenu = ({ isDesktop }: TopMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const user = useUser();
  const { cartQuantity, isLoggedIn } = selectUserType(user);
  const menuData = generateUtilityMenuData({ isDesktop, isLoggedIn, t });

  return menuData ? (
    <>
      {menuData.map((linkData, index) => {
        const { children, link, name } = linkData;
        const linkContent = buildMenuLinkContent({
          linkData,
          isLoggedIn,
          cartQuantity,
        });

        return (
          <ListItem
            data-testid={`nav-header-menu-item-${index}`}
            key={`nav-header-menu-item-${index}`}
            marginX={[null, null, null, '4']}>
            <SFErrorBoundary
              fallbackComponent={<MenuItemErrorFallback />}
              targetComponent="NavHeader TopMenu">
              <MenuLink
                linkPath={link}
                linkContent={linkContent}
                linkChildren={children}
                topLinkIndex={index}
                isDesktop={isDesktop}
                name={name}
              />
            </SFErrorBoundary>
          </ListItem>
        );
      })}
    </>
  ) : null;
};

export default TopMenu;
