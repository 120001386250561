import {
  SuperHeroBlockProps,
  ConnectedSuperHeroBlockProps,
} from './SuperHeroBlock.d';
import {
  SuperHeroBlockMain,
  SuperHeroBlockContent,
  SuperHeroText,
  SuperHeroCTA,
} from './SuperHeroBlock.components';
import { useRouter } from 'next/router';
import { SuperHeroMediaSkeleton } from './SuperHeroSkeletons';
import useAnalytics from '@/hooks/useAnalytics';
import useUser from '@/hooks/useUser';

export function SuperHeroBlock(props: SuperHeroBlockProps): JSX.Element {
  const {
    pre_header,
    headline,
    post_header,
    body_text,
    mobile_text_color,
    tablet_text_color,
    desktop_text_color,
    text_color,
    ...data
  } = props.data;

  // base, mobile, tablet, desktop
  const colors = [
    mobile_text_color,
    mobile_text_color,
    tablet_text_color,
    desktop_text_color,
  ].map(col => col || text_color);

  return data ? (
    <SuperHeroBlockMain {...data}>
      <SuperHeroBlockContent color={colors} {...data}>
        {pre_header ? (
          <SuperHeroText
            className="block-content__pre-header"
            data-testid="super-hero-pre-header"
            variant="heading"
            as="p"
            size="xs">
            {pre_header}
          </SuperHeroText>
        ) : null}
        <SuperHeroText
          className="block-content__header"
          data-testid="super-hero-header"
          as="h4"
          variant="heading"
          size="3xl">
          {headline}
        </SuperHeroText>
        {post_header ? (
          <SuperHeroText
            className="block-content__post-header"
            data-testid="super-hero-post-header"
            variant="heading"
            as="p"
            size="xs">
            {post_header}
          </SuperHeroText>
        ) : null}
        {body_text ? (
          <SuperHeroText
            className="block-content__body"
            data-testid="super-hero-body"
            variant="body"
            size="lg"
            mb="0">
            {body_text}
          </SuperHeroText>
        ) : null}
        {data?.link_text ? <SuperHeroCTA {...data} /> : null}
      </SuperHeroBlockContent>
    </SuperHeroBlockMain>
  ) : (
    <SuperHeroMediaSkeleton />
  );
}

export function ConnectedSuperHeroBlock(
  props: ConnectedSuperHeroBlockProps
): JSX.Element {
  const { data, region } = props;
  const { link_url, headline } = data;
  const router = useRouter();
  const { track } = useAnalytics();
  const { is_subscriber: isSubscriber } = useUser();

  const callToAction = () => {
    track('Super Hero Content Block Click', {
      catalog: 'Home Page',
      label: headline,
      region,
      is_subscriber: isSubscriber || false,
    });
    if (link_url.includes('/same-day')) {
      window.location.href = link_url;
    } else {
      router.push(link_url);
    }
  };

  return <SuperHeroBlock data={{ ...data, callToAction }} />;
}
